export const faq = [
  {
    question: "Do I need to download the app to play free games on EpicPlay?",
    answer:
      "No, playing our games doesn’t require you to download or install the games. You can directly play them on your browser, provided there is an internet connection.",
  },
  {
    question: "Do I need a high-end gaming laptop for free games on EpicPlay?",
    answer:
      "No, our games are meant for browsers and do not require any particular laptop or computer specifications. You can easily log on to epicplay.in from your browser and play games for free.",
  },
  {
    question: "What games can I play online for free on EpicPlay?",
    answer:
      "You can play simple, fun, and exciting games such as CallBreak, 2048, Ludo Matka, Fusion Blocks, Sudo, Meteor Attack, and many others on Epicplay.",
  },
  {
    question: "Which is the no. 1 online game on EpicPlay?",
    answer:
      "Cricket Star has been played more than 1 million times and is loved by our users. The game lets you enjoy cricket online, hit powerful shots, and score points.",
  },
  {
    question: "Can I play all the free games on my phone?",
    answer:
      "Yes, you can play all the free games on your phone by logging on to epicplay.in from your phone’s browser.",
  },
  {
    question: "How to play games on the internet?",
    answer:
      "Both paid and free games are available on the internet through mobile apps or browser games. You can download them from Apple App Store or Google Play Store or visit gaming websites to play free games online.",
  },
];
