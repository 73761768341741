export function getSchemaItem(item) {
  return {
    "@type": "Question",
    name: item.question,
    acceptedAnswer: {
      "@type": "Answer",
      text: item.answer,
    },
  };
}

export const generateFAQSchema = (
  data,
  faqlist
) => ({
  "@context": "https://schema.org",
  "@type": "FAQPage",
  name: data?.name ? data.name : "Frequently Asked Questions",
  ...(data?.description && { description: data.description }),
  mainEntity: [...faqlist.map(item => getSchemaItem(item))],
});
