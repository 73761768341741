import React, { useEffect, useState, useRef } from "react";
import useAuthContext from "src/contexts/AuthContext";
import { nanoid } from "nanoid";

const AD_UNIT_DETAILS = {
  url: undefined,
  userId: undefined,
  unitId: undefined,
  width: undefined,
  height: undefined,
  impressionId: undefined,
  userAgent: undefined,
};

function AdCheck({ pid }) {
  const [adDetails, setAdDetails] = useState(null);
  const { authUser } = useAuthContext();
  const elementRef = useRef();
  useEffect(() => {
    if (adDetails || !elementRef.current || !authUser) return;
    const targetNode = elementRef.current.previousSibling;
    if (
      targetNode.getAttribute("data-ad-status") === "unfilled" ||
      targetNode.getAttribute("data-ad-status") === "filled"
    ) {
      setAdDetails({
        url: window.location.href,
        userId: authUser.userId,
        unitId: pid,
        impressionId: nanoid(),
        userAgent: window.navigator.userAgent,
        width: getComputedStyle(targetNode).width.split("px")[0],
        height: getComputedStyle(targetNode).height.split("px")[0],
        deviceType: window.innerWidth <= 768 ? "Mobile" : "Desktop",
      });
    }
    const config = { attributes: true };
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "attributes") {
          if (
            mutation.attributeName === "data-ad-status" &&
            targetNode.getAttribute(mutation.attributeName) === "filled"
          ) {
            setAdDetails({
              url: window.location.href,
              userId: authUser.userId,
              unitId: pid,
              impressionId: nanoid(),
              userAgent: window.navigator.userAgent,
              width: getComputedStyle(targetNode).width.split("px")[0],
              height: getComputedStyle(targetNode).height.split("px")[0],
              deviceType: window.innerWidth <= 768 ? "Mobile" : "Desktop",
            });
            observer.disconnect();
          } else if (
            mutation.attributeName === "data-ad-status" &&
            targetNode.getAttribute(mutation.attributeName) === "unfilled"
          ) {
            observer.disconnect();
          }
        }
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
    return () => {
      try {
        observer.disconnect();
      } catch {}
    };
  }, [adDetails, authUser, elementRef]);
  return (
    <>
      <img
        ref={elementRef}
        alt="pxl-check"
        width={1}
        height={1}
        style={{
          opacity: 0,
        }}
        src={
          !adDetails
            ? ""
            : `https://adrta.com/i?clid=ggm&paid=ggm&publisherId=0&kv2=${adDetails.url}&kv3=${adDetails.userId}&kv12=${adDetails.unitId}&kv1=${adDetails.width}X${adDetails.height}&kv11=${adDetails.impressionId}&kv27=${adDetails.userAgent}&kv24=${adDetails.deviceType}`
        }
      />
    </>
  );
}

export default AdCheck;
