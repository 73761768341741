import EPText from "components/Brand/EPText";
import { useEffect, useState } from "react";
import { faq } from "data/faq.js";
import { Disclosure, Transition } from "@headlessui/react";
import { IoIosArrowDown } from "react-icons/io";
import classes from "./styles.module.scss";
import Content from "components/Layout/Content";
import clsx from "clsx";
import { boldText } from "utils/boldText";
import Section from "components/AppDetails/common/Section";
import FAQSchema from "components/Blogs/FAQSchema";
import { useRouter } from "next/router";

export const Question = ({ item }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.card}>
      <div className={clsx(classes.question)} onClick={() => setOpen(!open)}>
        <span className={classes.text}>{item.question}</span>
        <IoIosArrowDown
          className={clsx(
            open && classes.accordionIconOpen,
            classes.accordionIcon
          )}
        />
      </div>
      {!Array.isArray(item.answer) ? (
        <p className={clsx(classes.answer, open && classes.show)}>
          {item.answer}
        </p>
      ) : (
        <Section
          faq
          classes={clsx(classes.answer, open && classes.show)}
          sectionData={item.answer}
        />
      )}
    </div>
  );
};
function FAQ({ list, noSchema = false,faqName, pageName="free online games" }) {
  const faqData = list || faq;
  const schemaData = {
    name: "Frequently Asked Questions"|| faqName,
    description:
      "In this section, we will address the frequently asked questions related to " + pageName + ".",
  };
  return (
    <Content>
      {!noSchema && <FAQSchema data={schemaData} items={faqData} />}
      <h2 className={classes.title}>Frequently Asked Questions</h2>
      <div className={classes.container}>
        {faqData.map((item, index) => (
          <Question key={index} index={index} item={item} />
        ))}
      </div>
    </Content>
  );
}

export default FAQ;
