import React from "react";
import clsx from "clsx";
function EPText({
  as = "p",
  bold = false,
  primary = false,
  children,
  ...props
}) {
  const Tag = as;
  return (
    <Tag
      className={clsx(
        "text-base ",
        bold ? "font-semibold" : "font-normal",
        primary ? "text-primary-content" : "text-secondary-content",
        as === "p" ? "text-justify" : "text-left"
      )}
      {...props}
    >
      {children}
    </Tag>
  );
}

export default EPText;
