import { ADSENSE_US_CLIENT_ID, ADSENSE_IN_CLIENT_ID } from "constants/index";

const ADSENSE_US_AD_UNITS = {
  atfGameDetails: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "7064988910",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  btfGameDetails: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "9635349926",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  atfHome1: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "8322268256",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  btfHome1: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "3349105189",
    classes: "respAd",
    style: {
      display: "block",
    },
  },
  atfBlogListing: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "5696104915",
    "data-ad-format": "auto",
    "data-full-width-responsive": "tr ue",
    style: {
      display: "block",
    },
  },
  btfBlogListing: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "5783696837",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  atfBlogDetails: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "3808308174",
    classes: "small-horizontal-ad",
    style: {
      display: "block",
    },
  },
  btfBlogDetails: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "5476613062",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  btfBlogDetailsArticle: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "1488147030",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  atfCategoryPage: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "9224286384",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  btfCategoryPage: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "2303654816",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  atfQuizGame: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "6406551351",
    classes: "small-horizontal-ad",
    style: {
      margin: "auto",
      display: "block",
    },
  },
  atfLeaderboardPage: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "2112083127",
    "data-ad-format": "horizontal",
    style: {
      display: "block",
    },
  },
  atfFavoritesPage: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "5093469687",
    "data-ad-format": "horizontal",
    style: {
      display: "block",
    },
  },
  atfProfilePage: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "3780388018",
    "data-ad-format": "horizontal",
    style: {
      display: "block",
    },
  },
  atfGettingStartedPage: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "1471549756",
    style: {
      display: "block",
    },
    classes: "small-horizontal-ad",
  },
  atfLetsBeginPage: {
    "data-ad-client": ADSENSE_US_CLIENT_ID,
    "data-ad-slot": "4097713091",
    style: {
      display: "block",
    },
    classes: "mobileFirstAd",
  },
};

const ADSENSE_IN_AD_UNITS = {
  atfGameDetails: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "1031027988",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  btfGameDetails: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "1677061090",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  atfHome1: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "4118819819",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  btfHome1: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "7735998171",
    classes: "respAd",
    style: {
      display: "block",
    },
  },
  atfBlogListing: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "7875632730",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  btfBlogListing: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "3381853956",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  atfBlogDetails: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "8442608941",
    classes: "small-horizontal-ad",
    style: {
      display: "block",
    },
  },
  btfBlogDetails: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "7129527279",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  btfBlogDetailsArticle: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "5258499254",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  atfCategoryPage: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "6926287227",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  btfCategoryPage: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "2987042214",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true",
    style: {
      display: "block",
    },
  },
  atfQuizGame: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "4793778018",
    classes: "small-horizontal-ad",
    style: {
      margin: "auto",
      display: "block",
    },
  },
  atfMangaQuizGame: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "4793778018",
    // "data-ad-format": "auto",
    // "data-full-width-responsive": "true",
    classes: "small-horizontal-ad",
    style: {
      margin: "auto",
      display: "block",
    },
  },
  atfLeaderboardPage: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "6997343875",
    "data-ad-format": "horizontal",
    style: {
      display: "block",
    },
  },
  atfFavoritesPage: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "9960604475",
    "data-ad-format": "horizontal",
    style: {
      display: "block",
    },
  },
  atfProfilePage: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "8182287244",
    "data-ad-format": "horizontal",
    style: {
      display: "block",
    },
  },
  atfGettingStartedPage: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "9883343155",
    style: {
      display: "block",
    },
    classes: "small-horizontal-ad",
  },
  atfLetsBeginPage: {
    "data-ad-client": ADSENSE_IN_CLIENT_ID,
    "data-ad-slot": "8531529195",
    style: {
      display: "block",
    },
    classes: "mobileFirstAd",
  },
};

export const adSenseAdUnits = ADSENSE_IN_AD_UNITS;
