function boldText(text) {
  const startTag = text.indexOf("<b>");
  const endTag = text.indexOf("</b>");
  if (startTag < 0) {
    return text;
  }
  const startstring = text.substring(0, startTag);
  const reststring = text.substring(endTag + 4, text.length);
  const boldtxt = text.substring(startTag + 3, endTag);
  const finaltext = (
    <>
      {startstring}
      <strong>{boldtxt}</strong>
      {reststring}
    </>
  );
  return finaltext;
}

module.exports = {
  boldText,
};
