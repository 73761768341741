import React from "react";
import ItemList from "../ItemList";
import Description from "../Description";
import Text from "components/Brand/Text";
import Heading from "components/Brand/Heading";
import GoogleAdSenseContainer from "adsenseAds/GoogleAdSenseContainer";
import { adSenseAdUnits } from "utils/variants";
import HideOnWWW from "components/HideOnWWW";
import Image from "next/image";

const Section = ({ sectionData, isCategory = false, faq, classes }) => {
  return (
    <div className={classes}>
      {sectionData.map((val, index) => (
        <div className="mb-6" key={index}>
          {val.title && (
            <Heading
              variant="subTitle"
              fontWeight="semibold"
              color="primary"
              as="h2"
            >
              {val.title}
            </Heading>
          )}
          {val.subTitle &&
            (val.noTag ? (
              <Text
                as="p"
                variant="normal"
                color="primary"
                fontWeight="semibold"
              >
                {val.subTitle}
              </Text>
            ) : (
              <Heading as="h3" variant="normal" fontWeight="semibold">
                {val.subTitle}
              </Heading>
            ))}
          {val.description && (
            <Description isFaq={faq} items={val.description} />
          )}
          <HideOnWWW>
            {index === 0 && isCategory && (
              <GoogleAdSenseContainer
                {...adSenseAdUnits.btfCategoryPage}
                adCheck
              />
            )}
          </HideOnWWW>
          {val.image && (
            <>
              <div className="relative mx-auto my-5 aspect-[2/1] max-w-[700px]">
                <Image
                  fill
                  className="m-auto object-scale-down"
                  src={val.image.path}
                  alt={val.image.alt}
                  title={val.image.title}
                />
              </div>
              <div className="blog-image-caption">{val.image.caption}</div>
            </>
          )}
          {val.numberItems && (
            <ItemList isFaq={faq} isNumberList items={val.numberItems} />
          )}
          {val.items && <ItemList isFaq={faq} items={val.items} />}
        </div>
      ))}
    </div>
  );
};

export default Section;
