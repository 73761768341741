import Text from "components/Brand/Text";
import Heading from "components/Brand/Heading";
import React from "react";
import { boldText } from "utils/boldText";
import clsx from "clsx";

function ItemList({ items, isNumberList, isFaq }) {
  return (
    <>
      <Text
        variant="large"
        as="ol"
        className={clsx(
          isFaq ? "text-base" : "text-xl",
          "mb-4 pl-4",
          isNumberList ? "list-decimal" : "list-disc"
        )}
      >
        {items?.length > 0 &&
          items.map((item, index) => {
            return <ListItem isFaq={isFaq} item={item} key={index} />;
          })}
      </Text>
    </>
  );
}

export default ItemList;

function ListItem({ item, isFaq }) {
  if (
    typeof item === "object" &&
    !React.isValidElement(item) &&
    item.type !== React.Fragment
  ) {
    const { title, subTitle, description, subItems } = item;
    return (
      <Text
        key={title || description}
        className={clsx(isFaq ? "text-base" : "text-xl", "mb-1")}
        as="li"
      >
        {title && (
          <>
            <Text as="span" fontWeight="bold">
              {title}
            </Text>
            :{" "}
          </>
        )}
        {subTitle && (
          <Heading as="h3" variant="normal" fontWeight="semibold">
            {subTitle}
          </Heading>
        )}
        {description && (
          <Text key={description} as="span">
            {description}
          </Text>
        )}
        {subItems && <ItemList items={subItems} />}
      </Text>
    );
  }
  return (
    <Text as="li" className={clsx(isFaq ? "text-base" : "text-xl", "mb-1")}>
      {item}
    </Text>
  );
}
