import clsx from "clsx";
import Text from "components/Brand/Text";
import React from "react";
import { boldText } from "utils/boldText";

const Description = ({ items, isFaq }) => {
  return (
    <>
      {!Array.isArray(items) ? (
        <Text className={clsx(isFaq ? "mb-2 text-base" : "mb-4 text-xl")}>
          {items}
        </Text>
      ) : (
        items?.map((item, index) => (
          <Text
            key={index}
            className={clsx(isFaq ? "mb-2 text-base" : "mb-4 text-xl")}
          >
            {item}
          </Text>
        ))
      )}
    </>
  );
};

export default Description;
