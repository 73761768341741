import React from "react";
import { generateFAQSchema } from "data/schemaCodes/faqSchema";

const FAQSchema = ({data,items}) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(generateFAQSchema(data,items)),
      }}
    />
  );
};

export default FAQSchema;
