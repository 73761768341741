import clsx from "clsx";
import { useEffect } from "react";
import { useRouter } from "next/router";
import AdCheck from "./AdCheck";
const defaultClassName = "adsbygoogle";
function PageAdRenderer({ classes, adCheck, ...rest }) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch {}
  }, []);
  return (
    <>
      <ins
        align="center"
        className={clsx(defaultClassName, classes && classes)}
        {...rest}
      ></ins>
      {/* {adCheck && <AdCheck pid={rest["data-ad-slot"]} />} */}
    </>
  );
}

// this will update the key whenever the page changes so that the ad is reloaded
export default function GoogleAdSenseContainer({ classes, adCheck, ...rest }) {
  const router = useRouter();
  return (
    <PageAdRenderer
      key={rest["data-ad-slot"] + router.asPath}
      classes={classes}
      adCheck={adCheck}
      {...rest}
    />
  );
}
